<template>
  <div>
    <el-breadcrumb style="margin: 0 0 6px 6px" separator="/">
      <!-- <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>名师管理</el-breadcrumb-item>
      <el-breadcrumb-item>名师列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <!-- <div class="tt">入库管理</div>
      <div class="xian"></div>-->
      <div class="list_box">
        <div class="tt1">名师列表</div>
        <div>
          <el-button
            size="mini"
            type="cha"
            icon="el-icon-search"
            @click="chaxun"
            >查询</el-button
          >
          <el-button
            type="add"
            size="mini"
            icon="el-icon-circle-plus-outline"
            @click="add"
            >添加</el-button
          >
          <el-button type="add" size="mini" @click="xiazai">下载模板</el-button>
          <el-button type="warning" size="mini" @click="daoru">导入</el-button>
        </div>
      </div>
      <div class="int_box">
        <div class="int_box_a">
          <span>名字：</span>
          <el-input
            size="small"
            class="int_width"
            placeholder="请输入名字"
            v-model="name"
            clearable
          ></el-input>
        </div>
        <div class="int_box_a">
          <span>电话：</span>
          <el-input
            size="small"
            class="int_width"
            placeholder="请输入电话"
            v-model="tel"
            clearable
          ></el-input>
        </div>
        <div class="int_box_a">
          <span>邮箱：</span>
          <el-input
            size="small"
            class="int_width"
            placeholder="请输入邮箱"
            v-model="email"
            clearable
          ></el-input>
        </div>
      </div>
      <template>
        <el-table
          :header-cell-style="tableHeader"
          :data="indexList"
          style="width: 100%"
          stripe
        >
          <el-table-column prop="id" label="ID号" width="60"></el-table-column>
          <el-table-column prop="famous_name" label="名字"></el-table-column>

          <el-table-column label="图片">
            <template v-slot="scope">
              <img
                class="list_img"
                :src="URL_img + scope.row.famous_pic"
                alt=""
              />
            </template>
          </el-table-column>
          <el-table-column prop="famous_title" label="职称"></el-table-column>
          <el-table-column prop="famous_tel" label="电话"></el-table-column>
          <el-table-column prop="famous_email" label="邮箱"></el-table-column>
          <el-table-column prop="add_date" label="创建时间"></el-table-column>
          <el-table-column width="260px" label="操作">
            <template v-slot="scope">
              <el-tooltip
                :enterable="false"
                effect="dark"
                content="编辑"
                placement="top-end"
              >
                <el-button
                  size="mini"
                  type="primary"
                  icon="iconfont icon-bianxie"
                  @click="edit(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-button
                size="mini"
                type="success"
                @click="yuyueshijian(scope.row)"
                >预约时间</el-button
              >
              <el-button size="mini" type="success" @click="yuyue(scope.row)"
                >预约列表</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 15, 30, 50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <el-dialog
        :title="text"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="1000px"
        :before-close="handleClose"
      >
        <div class="xian2"></div>
        <div style="max-height: 500px; overflow-y: scroll">
          <el-form
            ref="form"
            :model="addform"
            :rules="rules"
            label-width="140px"
          >
            <el-form-item label="姓名：" prop="name">
              <el-input
                size="small"
                style="width: 450px"
                v-model="addform.name"
                placeholder="请输入姓名"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="职称：" prop="positional_titles">
              <el-input
                size="small"
                style="width: 450px"
                v-model="addform.positional_titles"
                placeholder="请输入职称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="电话：" prop="tel">
              <el-input
                size="small"
                style="width: 450px"
                v-model="addform.tel"
                placeholder="请输入电话"
                clearable
              ></el-input>
            </el-form-item>

            <el-form-item label="上传图片：" prop="pic">
              <el-upload
                class="avatar-uploader"
                :action="URL + 'edulogin.php/ajax/upload'"
                :show-file-list="false"
                :with-credentials="true"
                :before-upload="beforeAvatarUpload"
                :on-success="handleAvatarSuccess"
              >
                <img
                  v-if="addform.pic"
                  :src="URL_img + addform.pic"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="简介：" prop="introduction">
              <el-input
                size="small"
                style="width: 450px"
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 6 }"
                v-model="addform.introduction"
                placeholder="请输入简介"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="主讲内容：">
              <el-input
                size="small"
                style="width: 450px"
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 6 }"
                v-model="addform.main_content"
                placeholder="请输入简介"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="邮箱：">
              <el-input
                size="small"
                style="width: 450px"
                v-model="addform.email"
                placeholder="请输入邮箱"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="是否锁定：" v-if="id">
              <el-radio-group v-model="addform.locked">
                <el-radio :label="0">否</el-radio>
                <el-radio :label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="是否删除：" v-if="id">
              <el-radio-group v-model="addform.delete">
                <el-radio :label="0">否</el-radio>
                <el-radio :label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <el-button v-if="id" type="cha" class="add_btn" @click="edit_btn"
          >确定</el-button
        >
        <el-button v-else type="cha" class="add_btn" @click="add_btn"
          >确定</el-button
        >
      </el-dialog>
      <el-dialog
        title="导入"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible_dr"
        width="700px"
        :before-close="handleClose_dr"
      >
        <el-form label-width="140px">
          <el-form-item label="上传文件：">
            <el-upload
              class="upload-demo"
              style="width: 450px"
              :with-credentials="true"
              :action="URL + 'edulogin.php/ajax/upload'"
              :data="data"
              :on-remove="handleRemove"
              :on-success="handleSuccess"
              :limit="1"
              :on-exceed="handleExceed"
              :file-list="fileList"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
        <el-button type="cha" class="add_btn1" @click="daoru_btn"
          >确定</el-button
        >
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  inject: ['reload'],
  data() {
    return {
      page: 1,
      limit: 10,
      name: '',
      tel: '',
      email: '',

      indexList: [],
      total: 0,
      tableHeader: {
        'background-color': '#EEF1F6',
        'border-top': '2px solid #1CA6C8',
        color: '#333',
      },

      dialogVisible: false,
      addform: {
        name: '',
        positional_titles: '',
        introduction: '',
        tel: '',
        pic: '',
        email: '',
        main_content: '',
        locked: 0,
        delete: 0,
      },

      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        positional_titles: [
          { required: true, message: '请输入职称', trigger: 'blur' },
        ],

        tel: [{ required: true, message: '请输入电话', trigger: 'blur' }],
        pic: [{ required: true, message: '请选择图片', trigger: 'blur' }],
        introduction: [
          { required: true, message: '请输入简介', trigger: 'blur' },
        ],
      },
      id: '',
      text: '',
      URL: '',
      URL_img: '',
      // 导入参数
      dialogVisible_dr: false,
      fileList: [],
      data: {
        type_up:1
      },
      files_url: '',
    }
  },
  mounted() {
    this.index()
    this.URL = this.$URL
    this.URL_img = this.$URL_img
  },
  methods: {
    handleSizeChange(val) {
      this.limit = val
      this.index()
    },
    handleCurrentChange(val) {
      this.page = val
      this.index()
    },
    handleClose() {
      this.id = ''
      this.dialogVisible = false
      this.$refs.form.resetFields()
      this.addform = {
        name: '',
        positional_titles: '',
        introduction: '',
        tel: '',
        pic: '',
        email: '',
        main_content: '',
        locked: 0,
        delete: 0,
        no: '',
      }
    },
    handleClose_dr() {
      this.dialogVisible_dr = false
      this.fileList = []
      this.files_url = ''
    },
    // __token__令牌
    async __token__() {
      const { data: res } = await this.$http.get(
        'index.php/api/index/formtoken'
      )
      if (res.code !== 1) return this.$message.error(res.msg)
      this.token = res.data.info
    },
    // 列表
    async index() {
      const { data: res } = await this.$http.get(
        'edulogin.php/famous/teacher/index',
        {
          params: {
            page: this.page,
            limit: this.limit,
            name: this.name,
            tel: this.tel,
            email: this.email,
          },
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.indexList = res.data.list
      this.total = res.data.total
    },

    // 查询
    chaxun() {
      this.page = 1
      this.index()
    },
    handleAvatarSuccess(res, file) {
      this.addform.pic = res.data.url
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    // 跳转到预约列表
    yuyue(row) {
      window.localStorage.setItem('jxzong_activePath', '/reservation')
      this.$router.push('/reservation?famous_name=' + row.famous_name)
      this.reload()
    },
    // 跳转到预约时间
    yuyueshijian(row) {
      window.localStorage.setItem('jxzong_activePath', '/reservationTime')
      this.$router.push('/reservationTime?famous_name=' + row.famous_name)
      this.reload()
    },
    // 点击添加按钮，弹出添加弹出框
    add() {
      this.text = '添加'
      this.dialogVisible = true
      this.__token__()
    },

    // 添加确认
    add_btn() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整的数据')
        this.addform.__token__ = this.token
        const { data: res } = await this.$http.post(
          'edulogin.php/famous/teacher/add',
          this.addform
        )
        if (res.code != 1) {
          this.token = res.data.__token__
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.dialogVisible = false
        this.$refs.form.resetFields()
        ;(this.addform = {
          name: '',
          positional_titles: '',
          introduction: '',
          tel: '',
          pic: '',
          email: '',
          main_content: '',
          locked: 0,
          delete: 0,
        }),
          this.index()
      })
    },
    // 点击编辑
    edit(row) {
      this.text = '编辑'
      this.id = row.id
      this.dialogVisible = true
      this.addform = {
        name: row.famous_name,
        positional_titles: row.famous_title,
        introduction: row.famous_introduction,
        tel: row.famous_tel,
        pic: row.famous_pic,
        email: row.famous_email,
        main_content: row.main_content,
        locked: row.locked,
        delete: row.is_delete,
        no: row.famous_no,
      }
      this.__token__()
    },
    // 编辑确认
    async edit_btn() {
      this.addform.__token__ = this.token
      const { data: res } = await this.$http.post(
        'edulogin.php/famous/teacher/edit',
        this.addform
      )
      if (res.code !== 1) {
        this.token = res.data.__token__
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.$refs.form.resetFields()
      this.dialogVisible = false
      ;(this.addform = {
        name: '',
        positional_titles: '',
        introduction: '',
        tel: '',
        pic: '',
        email: '',
        main_content: '',
        locked: 0,
        delete: 0,
        no: '',
      }),
        (this.id = '')
      this.index()
    },
    // 下载模板
    xiazai() {
      window.open(this.URL + 'uploads/import_file/demo_famous.xlsx')
    },

    // 上传资源
    handleRemove(file, fileList) {
      this.files_url = ''
    },
    handleSuccess(res, file) {
      if (res.code != 1) {
        this.fileList = []
        return this.$message.error(res.msg)
      }
      this.files_url = res.data.url
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    // 导入弹出框
    daoru() {
      this.dialogVisible_dr = true
      this.__token__()
    },
    // 导入
    async daoru_btn() {
      const { data: res } = await this.$http.post(
        'edulogin.php/famous/teacher/importfamous',
        {
          __token__: this.token,
          files_url: this.files_url,
        }
      )
      if (res.code !== 1) {
        this.token = res.data.__token__
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.dialogVisible = false
      this.handleClose_dr()
      this.chaxun()
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .tt {
    font-size: 24px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 30px auto;
  }
  .list_box {
    width: 100%;
    height: 38px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .tt1 {
      font-size: 16px;
      padding: 2px 10px;
      border-left: 3px solid #1ca6c8;
    }
  }
  .int_box1 {
    padding: 0 1px 15px 1px;
  }
  .int_box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 15px 1px 0px;
  }
  .int_box_a {
    padding-bottom: 15px;
  }
  .int_width {
    width: 242px;
    margin-right: 40px;
  }
  span {
    color: #666;
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 15px 0;
  }
  .xian2 {
    width: 100%;
    height: 2px;
    background-color: #f0f0f0;
    margin-bottom: 20px;
  }
  .ttright {
    font-size: 14px;
    margin-left: 20px;
    color: #666;
  }
  .add_btn {
    width: 140px;
    margin: 40px 0 30px 420px;
  }
  .add_btn1 {
    width: 100px;
    margin: 40px 0 30px 280px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px dashed #d9d9d9 !important;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .list_img {
    width: 40px;
    height: 40px;
    display: block;
  }
}
</style>
